import React from 'react';
import ovseee from '../images/Oivseee.svg';

function Lead() {
	return (
		<section className="page-section hithere" id="about">
			<img src={ovseee} alt="Делаем неубиваемые стикеры" />
		</section>
	);
}

export default Lead;

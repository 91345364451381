import React from 'react';
import success from '../images/images/edit-success.png';
import denied from '../images/images/edit-denied.png';

function InfoTooltip({ isOpen, onClose, isFormValid }) {
	return (
		<section
			className={`popup popup-animation ${isOpen ? 'popup_opened' : ''}`}
			onClick={onClose}
		>
			<div className="popup__tooltip" onClick={(e) => e.stopPropagation()}>
				<img
					className="popup__tooltip-image"
					src={isFormValid ? success : denied}
					alt="success"
				/>
				<p className="popup__registration-status">
					{isFormValid
						? 'Ваша заявка успешно отправлена!'
						: 'Произошла ошибка. Пожалуйста свяжитесь с нами.'}
				</p>
				<button
					type="button"
					className="popup__close-icon overlay cursor"
					onClick={onClose}
				></button>
			</div>
		</section>
	);
}

export default InfoTooltip;

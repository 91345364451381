import React, { useState } from 'react';
import Slider from 'react-slick';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import cactus from '../images/slider/24.JPG';
import golography from '../images/slider/4.JPEG';
import rice from '../images/slider/25.JPEG';
import box from '../images/slider/26.JPEG';
import poster from '../images/slider/6.JPG';
import dj from '../images/slider/8.JPEG';
import red from '../images/slider/13.JPG';
import bus from '../images/slider/20.JPEG';
import pot from '../images/slider/5.JPEG';
import sand from '../images/slider/22.jpg';
import door from '../images/slider/11.jpg';
import vinyl from '../images/slider/19.JPEG';
import table from '../images/slider/3.jpg';
import pen from '../images/slider/12.JPG';
import scissors from '../images/slider/17.JPEG';
import forward from '../images/slider/18.JPEG';
import tree from '../images/slider/10.JPG';
import micro from '../images/slider/23.jpg';
import girl from '../images/slider/2.jpg';
import colors from '../images/slider/21.JPG';
import vpiska from '../images/slider/9.JPEG';
import banana from '../images/slider/15.JPG';
// import march from '../images/slider/1.jpg';
// import snowboard from '../images/slider/7.jpg';
// import hands from '../images/slider/14.JPG';
// import stairs from '../images/slider/16.JPEG';

const images = [
	{
		pic: cactus,
		description:
			'Промо наклейки для фестиваля документального кино «Beat Weekend»',
	},
	{
		pic: golography,
		description:
			'Голографические наклейки для мастерской украшений из дерева «Natura Accessories»',
	},
	{
		pic: door,
		description: 'Режим работы для шоу-рума необычных вещей «Durman Room»',
	},
	{
		pic: box,
		description: 'Стикеры для упаковки украшений ручной работы «TREND.AS»',
	},
	{
		pic: poster,
		description: 'Стикерпэйджи для кафе азиатской кухни «Xianggang»',
	},
	{ pic: dj, description: 'Личные стикеры для Dj Karm' },
	{
		pic: red,
		description:
			'Стикерпэйдж для краевой организации событийного волонтерства «Красволонтер»',
	},
	{
		pic: bus,
		description: 'Наклейки на стаканчики для кофейни «Bulli Coffee»',
	},
	{ pic: pot, description: 'Наклейки для упаковки натуральных свечей «Sveti»' },
	{
		pic: sand,
		description: 'Лист промо-наклеек для танцевального фестиваля «Qoud»',
	},
	{
		pic: rice,
		description: 'Режим работы для мастерской живописи «Рисоварка»',
	},
	{
		pic: vinyl,
		description: 'Промо-наклейки для винил-маркета «We have vinyl»',
	},
	{
		pic: table,
		description:
			'Подарочный стикерпэйдж для учеников Красноярской удаленной школы',
	},
	{
		pic: pen,
		description:
			'Промо-наклейки  для международного проекта популяризации науки «Science Slam»',
	},
	{
		pic: scissors,
		description: 'Стикерпэйдж с работами тату-мастера Марии Иволги',
	},
	{
		pic: forward,
		description:
			'Промо-наклейки для интернет-магазина автозапчастей «Форвард Авто»',
	},
	{ pic: tree, description: 'Стикерпэйдж для лаборатории искусства «PubLab»' },
	{ pic: micro, description: 'Техническое обеспечение мероприятий «Uchannel»' },
	{
		pic: girl,
		description:
			'Промо-наклейки для фестиваля документального кино «Beat Weekend»',
	},
	{ pic: colors, description: 'Промо-наклейки для граффити-магазина «FatCap»' },
	{ pic: vpiska, description: 'Стикерпэйдж для бара «Вписка»' },
	{
		pic: banana,
		description: 'Промо-наклейки  для магазина спортивных товаров «Коталити»',
	},
];

function SliderCorusel() {
	const [imageIndex, setImageIndex] = useState(0);

	const NextArrow = ({ onClick }) => {
		return (
			<div className="arrow next" onClick={onClick}>
				<FaArrowRight />
			</div>
		);
	};

	const PrevArrow = ({ onClick }) => {
		return (
			<div className="arrow prev" onClick={onClick}>
				<FaArrowLeft />
			</div>
		);
	};

	const settings = {
		infinite: true,
		lazyload: true,
		speed: 300,
		slidesToShow: 3,
		centerMode: true,
		centerPadding: '50px',
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		beforeChange: (current, next) => setImageIndex(next),
		className: 'slider__slide',
		dots: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					dots: false,
					centerPadding: '0',
				},
			},
		],
	};

	return (
		<section className="slider page-section">
			<div className="slider__container">
				<h2 className="text-center mt-0">Эти ребята уже с нами</h2>
				<hr className="divider divider-light" />
				<Slider {...settings}>
					{images.map((image, index) => (
						<div
							key={index}
							className={index === imageIndex ? 'slide active-slide' : 'slide'}
						>
							<img className="slider__image" src={image.pic} alt="img" />
							<div className="slider__description">
								<p className="slider__text">{image.description}</p>
							</div>
						</div>
					))}
				</Slider>
			</div>
		</section>
	);
}
export default SliderCorusel;

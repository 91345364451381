import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import Header from './Header';
import Lead from './Lead';
import About from './About';
import Services from './Services';
import Issue from './Issue';
import IssueNarrow from './IssueNarow';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Footer from './Footer';
import ParallaxLead from './ParallaxLead';
import Map from './Map';
import SliderCorusel from './Slider';
import { ParallaxProvider } from 'react-scroll-parallax';
import useWindowWidth from '../hooks/useWindowWidth';
import InfoTooltip from './InfoTooltip';

function App() {
	const [scroll, setScroll] = useState(0);
	const width = useWindowWidth();
	const [isNavbarActive, setNavbarActive] = useState(false);
	const [isInfoTooltipOpen, setisInfoTooltipOpen] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

	useEffect(() => {
		document.title = 'Stickermania.by';
	}, []);

	function handleValidForm() {
		setIsFormValid(true);
	}

	function handleSuccess() {
		setIsSuccess(true);
	}

	function handleErrorSubmit() {
		setIsSuccess(false);
	}

	function handleInvalidForm() {
		setIsFormValid(false);
	}

	function openInfoTooltip() {
		setisInfoTooltipOpen(true);
	}

	function closeInfoTooltip() {
		setisInfoTooltipOpen(false);
	}

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	const handleNavbarActiveToggler = () => {
		isNavbarActive ? setNavbarActive(false) : setNavbarActive(true);
	};

	const handleNavbarActive = () => {
		setNavbarActive(false);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	}, []);

	return (
		<ParallaxProvider>
			<div id="page-top">
				<Navigation
					scroll={scroll}
					isNavbarActive={isNavbarActive}
					setNavbarActiveToggler={handleNavbarActiveToggler}
					setNavbarActive={handleNavbarActive}
				/>
				<Header />
				{width > 1040 ? <ParallaxLead /> : <Lead />}
				<About />
				{width > 900 ? <Issue /> : <IssueNarrow />}
				<Services />
				<Portfolio />
				<SliderCorusel />
				<Contact
					openInfoTooltip={openInfoTooltip}
					handleInvalidForm={handleInvalidForm}
					handleValidForm={handleValidForm}
					isFormValid={isFormValid}
					handleSuccess={handleSuccess}
					handleErrorSubmit={handleErrorSubmit}
				/>
				<Map />
				<Footer />
				<InfoTooltip
					isOpen={isInfoTooltipOpen}
					onClose={closeInfoTooltip}
					isFormValid={isSuccess}
				/>
			</div>
		</ParallaxProvider>
	);
}

export default App;

import { useEffect, useState } from 'react';
import validator from 'validator';

const useValidation = (value, validations) => {
	const [isEmpty, setIsEmpty] = useState(true);
	const [isEmailError, setIsEmailError] = useState(false);
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		for (const validation in validations) {
			switch (validation) {
				case 'isEmpty':
					value ? setIsEmpty(false) : setIsEmpty(true);
					break;
				case 'isEmail':
					validator.isEmail(value)
						? setIsEmailError(false)
						: setIsEmailError(true);
					break;
				default:
					break;
			}

			isEmpty || isEmailError ? setIsValid(false) : setIsValid(true);
		}
	}, [value, isEmailError, validations, isEmpty, isValid]);

	return {
		isEmpty,
		isEmailError,
		isValid,
	};
};

const useInput = (initialValue, validations, validateEmail) => {
	const [value, setValue] = useState('initialValue');

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	const [isDirty, setIsDirty] = useState(false);
	const valid = useValidation(value, validations, validateEmail);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const onBlur = (e) => {
		setIsDirty(true);
	};

	return {
		value,
		isDirty,
		onChange,
		onBlur,
		...valid,
	};
};

export default useInput;

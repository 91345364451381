import React, { useEffect, useState } from 'react';
import useInput from '../hooks/useInput';
import emailjs from 'emailjs-com';

function Contact({
	openInfoTooltip,
	handleValidForm,
	handleInvalidForm,
	isFormValid,
	handleSuccess,
	handleErrorSubmit,
}) {
	const [isSubmiting, setIsSubmiting] = useState(false);
	const nameValue = useInput('', { isEmpty: true });
	const phoneValue = useInput('', { isEmpty: true });
	const emailValue = useInput('', { isEmpty: true, isEmail: false });
	const textValue = useInput('', { isEmpty: true });

	function handleSubmitting() {
		setIsSubmiting(true);
	}

	function handleStopSubmiting() {
		setIsSubmiting(false);
	}

	useEffect(() => {
		nameValue.isValid &&
		phoneValue.isValid &&
		emailValue.isValid &&
		textValue.isValid
			? handleValidForm()
			: handleInvalidForm();
	}, [
		nameValue.isValid,
		phoneValue.isValid,
		emailValue.isValid,
		textValue.isValid,
		handleValidForm,
		handleInvalidForm,
	]);

	const templateParams = {
		name: nameValue.value,
		phone: phoneValue.value,
		email: emailValue.value,
		message: textValue.value,
	};

	function onSubmit(e) {
		e.preventDefault();
		handleSubmitting();
		emailjs
			.send(
				'service_ak5px1a',
				'template_nfj04cv',
				templateParams,
				'fr8bfni7g5vluCx05'
			)
			.then(
				function (response) {
					handleSuccess();
					openInfoTooltip();
					handleStopSubmiting();
				},
				function (error) {
					handleErrorSubmit();
					openInfoTooltip();
					handleStopSubmiting();
				}
			);
	}

	return (
		<section className="page-section" id="form">
			<div className="container px-4 px-lg-5">
				<div className="row gx-4 gx-lg-5 justify-content-center">
					<div className="col-lg-8 col-xl-6 text-center">
						<h2 className="mt-0">Оставь заявку и узнай стоимость</h2>
						<hr className="divider" />
						<p className="text-muted mb-5">
							Заполни форму и получи точный рассчет стоимости своего заказа
						</p>
					</div>
				</div>
				<div className="row gx-4 gx-lg-5 justify-content-center mb-5">
					<div className="col-lg-6">
						<form onSubmit={onSubmit} action="mail.php" method="POST">
							<div className={`form-floating mb-3`}>
								<input
									className={`form-control  ${
										nameValue.isEmpty && nameValue.isDirty ? `input-error` : ``
									}`}
									id="inputName"
									type="text"
									placeholder="Имя или название организации"
									value={nameValue.value}
									onChange={(e) => nameValue.onChange(e)}
									onBlur={(e) => nameValue.onBlur(e)}
								/>
								<label htmlFor="inputName">Имя или название организации</label>
							</div>
							<div className="form-floating mb-3">
								<input
									className={`form-control ${
										phoneValue.isEmpty && phoneValue.isDirty
											? `input-error`
											: ``
									}`}
									id="inputPhone"
									type="phone"
									placeholder="Телефон"
									value={phoneValue.value}
									onChange={(e) => phoneValue.onChange(e)}
									onBlur={(e) => phoneValue.onBlur(e)}
								/>
								<label htmlFor="inputEmail">Телефон</label>
							</div>
							<div className="form-floating mb-3">
								<input
									className={`form-control ${
										(emailValue.isEmpty || emailValue.isEmailError) &&
										emailValue.isDirty
											? `input-error`
											: ``
									}`}
									id="inputEmail"
									type="email"
									placeholder="Эл. почта"
									value={emailValue.value}
									onChange={(e) => emailValue.onChange(e)}
									onBlur={(e) => emailValue.onBlur(e)}
								/>
								<label htmlFor="inputPhone">Эл. почта</label>
							</div>
							<div className="form-floating mb-3 form-floating_textarea">
								<textarea
									className={`form-control form-control_textarea ${
										textValue.isEmpty && textValue.isDirty ? `input-error` : ``
									}`}
									id="inputMessage"
									type="text"
									placeholder="Размер, количество и назначение наклеек"
									value={textValue.value}
									onChange={(e) => textValue.onChange(e)}
									onBlur={(e) => textValue.onBlur(e)}
								></textarea>
								<label htmlFor="inputMessage">
									Размер, количество и назначение наклеек
								</label>
							</div>
							<div className="d-grid">
								<button
									className="btn btn-primary btn-xl"
									type="submit"
									disabled={isFormValid && !isSubmiting ? false : true}
								>
									{`${!isSubmiting ? `Отправить` : `Отправка...`}`}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contact;

import React, { useState } from 'react';
import bag from '../images/portfolio/fullsize/portfolio-bag.jpg';
import car from '../images/portfolio/fullsize/portfolio-car.jpg';
import paper from '../images/portfolio/fullsize/portfolio-paper.jpg';
import phone from '../images/portfolio/fullsize/portfolio-phone.jpg';
import window from '../images/portfolio/fullsize/portfolio-window.jpg';

function Portfolio() {
	const [bagRotate, setBagRotate] = useState(false);
	const [carRotate, setCarRotate] = useState(false);
	const [paperRotate, setPaperRotate] = useState(false);
	const [phoneRotate, setPhoneRotate] = useState(false);
	const [windowRotate, setWindowRotate] = useState(false);

	const handleBagRotate = () => {
		setBagRotate(!bagRotate);
	};

	const handleCarRotate = () => {
		setCarRotate(!carRotate);
	};

	const handlePaperRotate = () => {
		setPaperRotate(!paperRotate);
	};

	const handlePhoneRotate = () => {
		setPhoneRotate(!phoneRotate);
	};

	const handleWindowRotate = () => {
		setWindowRotate(!windowRotate);
	};

	return (
		<section className="page-section">
			<h2 className="text-center mt-0">Можем - умеем</h2>
			<p className="text-muted text-center mb-0">
				Ваш бренд в каждом стикере: от маленьких наклеек до огромных витрин
			</p>
			<hr className="divider" />
			<div id="portfolio" className="portfolio cursor">
				<div
					className={`card cursor ${phoneRotate ? '' : 'card_hover'}`}
					onClick={handlePhoneRotate}
				>
					<div
						className={`card__front ${phoneRotate ? 'card__front_hover' : ''}`}
					>
						<img className="card__front-image" src={phone} alt="телефон" />
						<span className="card__click">...</span>
					</div>
					<div
						className={`card__back ${phoneRotate ? 'card__back_hover' : ''}`}
					>
						<h2 className="card__header">
							Путешествующая
							<br />
							визитка
						</h2>
						<p className="card__description">
							Реклама, которая всегда с тобой - красиво и нативно.
						</p>
					</div>
				</div>
				<div
					className={`card cursor ${bagRotate ? '' : 'card_hover'}`}
					onClick={handleBagRotate}
				>
					<div
						className={`card__front ${bagRotate ? 'card__front_hover' : ''}`}
					>
						<img src={bag} className="card__front-image" alt="сумка" />
						<span className="card__click">...</span>
					</div>
					<div className={`card__back ${bagRotate ? 'card__back_hover' : ''}`}>
						<h2 className="card__header">
							Кастомизированная
							<br />
							упаковка
						</h2>
						<p className="card__description">
							Брендируйте самостоятельно даже дешевый краф-пакет в любом объеме.
						</p>
					</div>
				</div>
				<div
					className={`card cursor ${paperRotate ? '' : 'card_hover'}`}
					onClick={handlePaperRotate}
				>
					<div
						className={`card__front ${paperRotate ? 'card__front_hover' : ''}`}
					>
						<img src={paper} className="card__front-image" alt="бумага" />
						<span className="card__click">...</span>
					</div>
					<div
						className={`card__back ${paperRotate ? 'card__back_hover' : ''}`}
					>
						<h2 className="card__header">Стикерпэйдж</h2>
						<p className="card__description">
							Страница со стикерами. Каждая наклейка на странице вырезана по
							контуру.
						</p>
					</div>
				</div>
				<div
					className={`card cursor ${carRotate ? '' : 'card_hover'}`}
					onClick={handleCarRotate}
				>
					<div
						className={`card__front ${carRotate ? 'card__front_hover' : ''}`}
					>
						<img src={car} className="card__front-image" alt="машина" />
						<span className="card__click">...</span>
					</div>
					<div className={`card__back ${carRotate ? 'card__back_hover' : ''}`}>
						<h2 className="card__header">
							Плоттерная
							<br />
							резка
						</h2>
						<p className="card__description">
							Вырезаем текст и логотипы разных форм и цветов.
						</p>
					</div>
				</div>
				<div
					className={`card cursor ${windowRotate ? '' : 'card_hover'}`}
					onClick={handleWindowRotate}
				>
					<div
						className={`card__front card__front_window ${
							windowRotate ? 'card__front_hover' : ''
						}`}
					>
						<img src={window} className="card__front-image" alt="окно" />
						<span className="card__click">...</span>
					</div>
					<div
						className={`card__back ${windowRotate ? 'card__back_hover' : ''}`}
					>
						<h2 className="card__header">
							Очень большие
							<br />
							наклейки
						</h2>
						<p className="card__description">
							Самая большая наклейка в нашей практике 4,8х2,2м, но можем и
							больше. <br />
							При необходимости ещё и наклеим.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Portfolio;

import React from 'react';
import { Icon24LogoVk } from '@vkontakte/icons';

function Map() {
	return (
		<section className="map page-section" id="contact">
			<h2 className="text-center mt-0">Контакты</h2>
			<hr className="divider divider-light" />
			<div className="map__container container">
				<div className="map__contacts">
					<div className="map__contacts-item">
						<h3 className="map__contacts-heading">Адрес:</h3>
						<p className="map__contacts-text">
							г. Красноярск, ул. Карла Маркса 102A, 3 этаж, офис 3-10
						</p>
					</div>
					<div className="map__contacts-item">
						<h3 className="map__contacts-heading">E-mail:</h3>
						<p className="map__contacts-text">contactstickermania@mail.ru</p>
					</div>
					<div className="map__contacts-item map_last">
						<h3 className="map__contacts-heading ">Telegram:</h3>
						<a
							href="https://t.me/stickermaniaby"
							className="map__link cursor"
							target="_blank"
							rel="noreferrer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-telegram map__inst-icon"
								viewBox="0 0 16 16"
							>
								<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
							</svg>
							<p className="map__inst">stickermania.by</p>
						</a>
					</div>
					<div className="map__contacts-item map_last">
						<h3 className="map__contacts-heading ">ВКонтакте:</h3>
						<a
							href="https://vk.com/stickermaniaby"
							className="map__link cursor"
							target="_blank"
							rel="noreferrer"
						>
							<Icon24LogoVk
								color="black"
								fill="currentColor"
								className="bi bi-telegram map__inst-icon"
								width={20}
								height={20}
							/>
							<p className="map__inst">stickermania.by</p>
						</a>
					</div>
				</div>
				<iframe
					title="map"
					className="map__frame"
					src="https://yandex.ru/map-widget/v1/?um=constructor%3A1ed635a61195caaf5dc4facb7d013c1134750e4d97c1119ce40cd4a393802389&amp;source=constructor"
					frameBorder="0"
				></iframe>
			</div>
		</section>
	);
}

export default Map;
